<template>
  <div>
    <template v-if="true">
      <div class="board-list">
        <div class="board-list__heading">
          <div class="heading__item">
            Board name
            <div @click="onSortHandler">
              <!-- prettier-ignore -->
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-if="sort === 'ASC'" v-bind:class="'heading__icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 20a1 1 0 01-1-1V7.414l-4.657 4.657a1 1 0 01-1.414-1.414l6.364-6.364a1 1 0 011.414 0l6.364 6.364a1 1 0 01-1.414 1.414L13 7.414V19a1 1 0 01-1 1z"/></svg>
              <!-- prettier-ignore -->
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-else="" v-bind:class="'heading__icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 4a1 1 0 011 1v11.586l4.657-4.657a1 1 0 011.414 1.414l-6.364 6.364a1 1 0 01-1.414 0l-6.364-6.364a1 1 0 111.414-1.414L11 16.586V5a1 1 0 011-1z"/></svg>
            </div>
          </div>
          <div class="heading__item">Last viewed</div>
          <div class="heading__item">Created</div>
          <div class="heading__item">Action</div>
        </div>
        <template v-for="board in prepareBoardList" :key="board.id">
          <div class="board-list__item" @click="openBoard(board)" @contextmenu.prevent="">
            <div class="item__name">{{ board.name }}</div>
            <div>{{ board.lastUpdate.format('YYYY-MM-DD HH:mm') }}</div>
            <div>{{ board.createDate.format('YYYY-MM-DD HH:mm') }}</div>
            <!-- <div>{{ board.createdAt.format("DD/MM/YY HH:mm") }}</div> -->
            <div style="margin: 0 auto; fill: var(--color-secondary-light);">
              <template v-if="board.isOwner">
                <PopperDropdown
                    :placement="'bottom-end'"
                    @update:shown="(v) => v || resetContactSelectedWithContextMenu()"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" @click.stop="contactSelectedWithContexMenu = board" v-bind:svg-inline="''" v-bind:class="'sccl-icon-menu'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M10 6a2 2 0 114 0 2 2 0 01-4 0zm0 6a2 2 0 114 0 2 2 0 01-4 0zm2 4a2 2 0 100 4 2 2 0 000-4z"/></svg>
                  <template #popper="{ hide }">
                    <ContextMenu :menuList="menuList" :hide="hide" />
                  </template>
                </PopperDropdown>
              </template>
            </div>
            
            
          </div>
        </template>
      </div>
    </template>
    <template v-else>
      <EmptyPlug :title="'There is no boards. Create new one'">
        <svg width="147" height="147" viewBox="0 0 147 147" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M73 56a2 2 0 012 2v14a2 2 0 002 2h14a2 2 0 110 4H77a2 2 0 00-2 2v14a2 2 0 11-4 0V80a2 2 0 00-2-2H55a2 2 0 110-4h14a2 2 0 002-2V58a2 2 0 012-2z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M24.5 47.625c0-12.703 10.297-23 23-23h21.03a12 12 0 018.485 3.515l5.22 5.22a12 12 0 008.486 3.515H99.5c12.703 0 23 10.297 23 23V93.5c0 12.703-10.297 23-23 23h-52c-12.703 0-23-10.297-23-23V47.625zm95 12.25V93.5c0 11.046-8.954 20-20 20h-52c-11.046 0-20-8.954-20-20V47.625c0-11.046 8.954-20 20-20h21.03a9 9 0 016.363 2.636l5.221 5.22a15 15 0 0010.607 4.394H99.5c11.046 0 20 8.954 20 20z"/></svg>
      </EmptyPlug>
    </template>
  </div>
</template>

<script>

import EmptyPlug from "@component/EmptyStates";
//
import PopperDropdown from "@component/Popper/PopperDropdown";
import ContextMenu from "@component/UI/ContextMenu";
import BoardCreateForm from '@component/Board/BoardCreateForm';
import {communityGetSelected, communityGetList} from '@store/community.store';
import CommunityEditContact from '@component/Community/CommunityEditContact';
import {diffList} from '@store/utils';

export default {
  name: 'BoardList',
  components: {
    EmptyPlug,
    ContextMenu,
    PopperDropdown,
  },
  data() {
    return {
      sort: "ASC", // || 'DESC'
      // prettier-ignore
      boardList: [],
      // Получаем контакт, котором открыли контекстное меню
      contactSelectedWithContexMenu: null,
      menuList: [
        { name: 'Rename',       click: () => { this.openModalBoardEdit(); } },
        { name: 'Change Share', click: () => { this.openModalBoardContactEdit()   }, },
        { name: 'Delete',       click: () => { this.openModalBoardDelete(); }, class: 'text-red' },
      ]
    };
  },
  computed: {
    prepareBoardList() {
      const { sort } = this;
      let boardList = this.boardList.map((board) => {
        const createdAt = DateTime(board.createdAt);
        const lastEditedAt = DateTime(board.lastEditedAt);
        return {
          ...board,
          createdAt: createdAt.format("DD/MM/YYYY HH:mm"),
          lastEditedAt:
            lastEditedAt.diff(Date.now(), "day") === 0
              ? "today"
              : lastEditedAt.format("DD/MM/YYYY HH:mm"),
        };
      });

      const communityObj = communityGetSelected();
      if(communityObj.value && communityObj.value.id) {
        boardList = boardList.filter((item) => (item.communityId === communityObj.value.id));
      }
      
      return boardList.sort(function (a, b) {
        if (a.name < b.name) {
          return sort === "ASC" ? -1 : 1;
        }
        if (a.name > b.name) {
          return sort === "ASC" ? 1 : -1;
        }
        return 0;
      });
    },
  },
  methods: {
    resetContactSelectedWithContextMenu() {
      this.contactSelectedWithContexMenu = null;
    },
    onSortHandler() {
      this.sort = this.sort === "ASC" ? "DESC" : "ASC";
    },
    openBoard(board) {
      this.$router.push({ name: this.$routeName.BOARD_CREATE, params: { id: board.id }});
    },
  
  
  
  
    //
    async openModalBoardContactEdit( /* boardObject */ ) {
      //
      let boardObject = this.contactSelectedWithContexMenu;
      if(!boardObject){ return; }
  
      const boardFullObject = await RequestManager.Board.getById({id: boardObject.id })
      
      /////// {
      //
      let modal = null;
      const closeModal = () => {
        modal && modal.close();
      };
    
      let selectedContactObj = {};
      let participantsDiffStart = [];
      const userId = this.$user.Auth.getUserId();
      for (let i = 0; i < boardFullObject.participants.length; i++) {
        if(userId === boardFullObject.participants[i].id) {
          continue;
        }
        selectedContactObj[boardFullObject.participants[i].id] = boardFullObject.participants[i];
        participantsDiffStart.push(boardFullObject.participants[i]);
      }
  
  
      const communityList = communityGetList();
      const props = {
        // data
        communityList: communityList,
        selectedContactObj: selectedContactObj,
        // events
        onSubmit: async (submitObj) => {
          const diff = diffList(participantsDiffStart, submitObj.participants);
  
          if(diff.add.length) {
            await RequestManager.Board.addUserById({
              id: boardFullObject.id,
              userIdList: diff.add.map((item) => (item.id)),
            });
          }
          if(diff.delete.length) {
            await RequestManager.Board.deleteUserById({
              id: boardFullObject.id,
              userIdList: diff.delete.map((item) => (item.id)),
            });
          }
          
          closeModal();
        },
      };
      //
      modal = this.$dialog.open(CommunityEditContact, props, {
        group: "modal",
        theme: "community",
        close: true,
      });
      modal.then(() => {
        modal = null;
      });
    },
    //
    openModalBoardEdit( /* boardObject */ ) {
      //
      let boardObject = this.contactSelectedWithContexMenu;
      if(!boardObject){ return; }
  
      ///////
      let modal = null;
      const closeModal = () => { modal && modal.close(); };
    
      const props = {
        // data
        formData: boardObject,
        // events
        onSubmit: (submitObj) => {
          RequestManager.Board.updateById({
            id: boardObject.id,
            name: submitObj.name,
            description: submitObj.description,
          }).then( () => {
            this.load();
            closeModal();
          })
        },
      };
      //
      modal = this.$dialog.open(BoardCreateForm, props, { group: 'modal', theme: 'community', close: true, });
      modal.then(() => { modal = null; });
    },
    openModalBoardDelete() {
      //
      let boardId = this.contactSelectedWithContexMenu?.id;
      if(!boardId){ return; }

      let modal = this.$dialog.prompt.delete('Delete board?', 'Are you sure you want to delete?');
      modal.then((res) => {
        if (res && res.action === "OK") {
          RequestManager.Board.delete({id: boardId}).then( () => {
            this.load();
          })
        }
        modal = null;
      });
    },
    
    load() {
      RequestManager.Board.getAll().then(
          (boardResponse) => {
            this.boardList = boardResponse.list;
          }
      );
    }
  },
  mounted() {
    this.load();
  },
};
</script>

<style lang="scss" scoped>
.board-list {
  .board-list__heading {
    color: var(--color-gray-05);
    font-size: 0.875rem;
    margin-bottom: 16px;
  }
  .board-list__heading,
  .board-list__item {
    padding: 0 32px;
    display: grid;
    grid-gap: 0 20px;
    grid-template-columns: auto 180px 180px 50px;
  }

  .heading__item {
    display: flex;
    align-items: center;
  }
  .heading__icon {
    @include icon-size(1);
    fill: var(--color-gray-05);
    margin-left: 4px;
    cursor: pointer;
    display: block;
  }

  .board-list__item {
    cursor: pointer;
    padding-top: 6px;
    padding-bottom: 6px;
    margin-bottom: 4px;
    transition: 0.25s ease-in-out;
    &:hover {
      background-color: var(--whiteboard-list-item-hover);
    }
  }
  
  .item__name {
    font-weight: 600;
  }
}
</style>
