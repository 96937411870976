<template>
  <div>
    <div class="th-px" style="margin: 0 0 24px">
      <FveForm class="">
        <div class="">
          <FveText
              :field="field.name"
              label="Name"
              placeholder="Board name"
          />
        </div>
        <div class="mdl-community__field">
          <FveTextarea
              :field="field.description"
              label="Description"
              placeholder=""
          />
        </div>
      </FveForm>
    </div>
    <div class="btn-group th-px">
      <!-- prettier-ignore -->
      <button type="button" class="btn btn--outline" @click="close">Discard</button>
      <!-- prettier-ignore -->
      <button type="button" class="btn btn--primary" @click="submit">Save</button>
    </div>
  </div>
</template>

<script>

import FveMixinForm    from '@fve/Mixin/FveMixinForm';

import FveText from '@fve/Element/Text/FveText';
import FveTextarea from '@fve/Element/Text/FveTextarea';

export default {
  name: 'BoardCreateForm',
  mixins: [
    FveMixinForm
  ],
  components: {
    FveTextarea,
    FveText,
  },
  methods: {
    formSchema() {
      return {
        name: {
          type: String,
          default() { return ''; },
          field: {
            required: true,
          }
        },
        description: {
          type: String,
          default: () => { return ''; },
          field: {
            // required: true,
          }
        },
      };
    },
  }

};

</script>

<style lang="scss" scoped>
.mdl-community__form {
  display: grid;
  grid-gap: 0.875rem 1rem;
  align-items: flex-start;
  grid-template-columns: 3rem 1fr;
  
  .mdl-community__field {
    grid-column: 3 / 3;
  }
}
</style>
