<template>
  <div class="empty-states">
    <!--
      Using slot here are neccesary!
      Why? Described here: https://github.com/oliverfindl/vue-svg-inline-loader/issues/2
    -->
    <slot></slot>
    <span class="empty-states__title">{{ title }}</span>
  </div>
</template>

<script>

export default {
  name: "EmptyStates",
  props: {
    title: {
      type: String,
      default: "No content"
    }
  },
};
</script>

<style lang="scss" scoped>

.empty-states {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  width: 308px;
  padding: 77px 50px;
  border: 1px solid var(--color-gray-03);
  border-radius: 8px;

  ::v-deep(svg) {
    fill: var(--color-gray-03);
  }

  .empty-states__title {
    display: inline-block;
    max-width: 206px;
    word-wrap: break-word;
    text-align: center;
    margin-top: 8px;
    color: var(--color-gray-04);
    line-height: 1.47;
    font-size: 22px;
  }

}
</style>